<template>
  <div>
    <form-modal
      :cols="cols"
      :errorMessage="errorMessage"
      :maxWidth="750"
      :opened.sync="adicionarModal"
      :opts="opts"
      title="Adicionar horas"
      :value.sync="selectedItem"
      @cancel="adicionarModal = false"
      @save="doSave"
    ></form-modal>
    
    <form-modal
      :cols="empresaFields"
      :errorMessage="errorMessage"
      :maxWidth="500"
      :opened.sync="adicionarEmpresaModal"
      :opts="opts"
      title="Adicionar Empresa"
      :value.sync="selectedItem"
      @cancel="adicionarEmpresaModal = false"
      @save="doSaveEmpresa"
    ></form-modal>
    
    <form-modal
      :cols="projetoFields"
      :errorMessage="errorMessage"
      :maxWidth="500"
      :opened.sync="adicionarProjetoModal"
      :opts="opts"
      title="Adicionar Projeto"
      :value.sync="selectedItem"
      @cancel="adicionarProjetoModal = false"
      @save="doSaveProjeto"
    ></form-modal>
    
    <form-modal
      :cols="atividadeFields"
      :errorMessage="errorMessage"
      :maxWidth="500"
      :opened.sync="adicionarAtividadeModal"
      :opts="opts"
      title="Adicionar Atividade"
      :value.sync="selectedItem"
      @cancel="adicionarAtividadeModal = false"
      @save="doSaveAtividade"
    ></form-modal>

    <v-dialog
      v-model="exportacaoAsync"
      scrollable
      persistent
      max-width="500"
      @keydown.esc="exportacaoAsync = false"
    >
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-file-delimited-outline</v-icon> 
          Exportar CSV
          <v-btn
            @click="exportacaoAsync = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>Selecione o período para exportar:</p>
          <v-menu
            v-model="dateMenu"
            :close-on-click="competenciaExportacao.length == 2"
            :close-on-content-click="false"
            transition="scroll-y-transition"
            offset-y
            right
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="competenciaExportacao | toMonthPeriod"
                dense
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                @click:prepend.stop="on.click"
                v-on="on"
                hide-details
                class="mb-n1 mt-0 mr-3"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="competenciaExportacao"
              range
              type="month"
              @click:month="dateMenu = competenciaExportacao.length != 2"
              no-title
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="exportacaoAsync = false"
          >
            <v-icon left>mdi-chevron-left</v-icon> 
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="doAsyncExport()"
            > 
              Executar
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showSucessExport"
      scrollable
      persistent
      max-width="400"
      @keydown.esc="showSucessExport = false"
    >
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-file-delimited-outline</v-icon> 
          Exportar CSV
          <v-btn
            @click="showSucessExport = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>Requisição entrou na fila para processamento.</p>
          <p>Para ver o progresso e baixar o relatório, veja a fila.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="showSucessExport = false"
          >
            <v-icon left>mdi-chevron-left</v-icon> Fechar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="verFila()"
          > 
            Ver fila
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <MasterDetail
      :actionBarButtons="actionBarButtons"
      :canEdit="false"
      :cols="cols"
      :confirmDeleteMessage="confirmDeleteMessage"
      :customResource="customResource"
      :disableTableSaveButton="disableTableSaveButton"
      :filterQuery="filterQuery"
      formTitle="Alocar Horas — Galapos"
      :hasNewButton="false"
      :hasExportCSV="false"
      :lastRowData="horasTotaisPorDia"
      noReloadOnDelete
      :opts="opts"
      @mounted="doLoad = $event.doLoad"
    >
      <div class="d-flex">
        <v-menu
          v-model="periodMenu"
          :close-on-click="false"
          :close-on-content-click="false"
          transition="scroll-y-transition"
          offset-y
          right
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              style="width: 370px"
              prefix="Período:"
              :value="period | toPeriod"
              dense
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              @click:prepend.stop="on.click"
              v-on="on"
              hide-details
              class="mb-n1 mt-0"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="period"
            range
            no-title
            :events="highlightWeekOver"
            :event-color="tableColor"
            @click:date="dateClick()"
            @mouseenter:date="highlightWeek"
            @mouseleave:date="highlightWeekOver = []"
          ></v-date-picker>
        </v-menu>
        <v-autocomplete
          v-if="isAdmin"
          v-model="consultorId"
          :items="consultores"
          class="mb-n1 mt-0 ml-3"
          style="width: 370px"
          item-value="id"
          item-text="nome"
          prefix="Colaborador"
          prepend-icon="mdi-account"
          placeholder="Todos"
          dense
          clearable
          hide-details
        ></v-autocomplete>
      </div>
    </MasterDetail>
  </div>
</template>

<script>
import _, { cloneDeep, forEach, remove } from "lodash";
import moment from "moment";
import { mapGetters } from 'vuex';
import MasterDetail from "@/components/master-detail.vue";
import { UserTypeEnum } from '@/core/enums/user-types';
import toWeekDay from "@/helpers/toWeekDay";
import compareFloatNum from "@/helpers/compareFloatNum";

export default {
  components: {
    "form-modal": () => import("@/components/form-modal.vue"),
    MasterDetail,
  },
  created: function () {
    let dataIni = this.$route.query.dataIni;
    let dataFim = this.$route.query.dataFim;

    if (dataIni && dataFim) {
      this.period = [dataIni, dataFim];
    } else {
      this.period = this.getWeekPeriod(moment());
    }

    this.resourceConsultores.get().then((response) => {
      this.consultores = response.consultores
        .sort((a, b) => a.nome.localeCompare(b.nome));
    });

    this.doLoadEmpresas();
    this.doLoadProjetos();
  },
  computed: {
    ...mapGetters(["clientId", "user"]),
    actionBarButtons: function () {
      const buttons = [
        {
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          show: true,
          action: () => {
            this.selectedItem = {};

            if (!this.isAdmin) {
              const consultorId = this.user.link_data?.consultorId;
              this.selectedItem = { consultores: [consultorId] };
            }

            this.adicionarModal = true;
          },
        },
        {
          text: "Empresa",
          icon: "mdi-notebook-plus",
          show: true,
          action: () => {
            this.selectedItem = {};
            this.adicionarEmpresaModal = true;
          },
        },
        {
          text: "Projeto",
          icon: "mdi-notebook-plus-outline",
          show: this.isAdmin,
          action: () => {
            this.selectedItem = {};
            this.adicionarProjetoModal = true;
          },
        },
        {
          text: "Atividade",
          icon: "mdi-text-box-plus-outline",
          show: this.isAdmin,
          action: () => {
            this.selectedItem = {};
            this.adicionarAtividadeModal = true;
          },
        },
        {
          text: "Exportação Assíncrona",
          icon: "mdi-file-delimited-outline",
          show: this.isAdmin,
          action: () => {
            this.selectedItem = {};
            this.exportacaoAsync = true;
          },
        },
      ];

      return buttons.filter(({ show }) => show);
    },
    atividadeFields: function () {
      return [
        {
          key: "tipo",
          name: "Tipo",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'tiposProjetos', id: 'name', name: 'name' },
          placeholder: 'Selecione o tipo da atividade',
          rules: [{ rule: 'required' }],
        },
        {
          key: "atividade",
          name: "Atividade",
          type: this.$fieldTypes.TEXT,
          placeholder: 'Escreva o nome da atividade',
          rules: [{ rule: 'required' }],
        },
      ];
    },
    cols: function () {
      const initialCols = [
        {
          key: "nome_consultor",
          name: "Colaborador",
          type: this.$fieldTypes.TEXT,
          hideInform: true,
        },
        {
          key: "fantasia",
          name: "Empresa",
          type: this.$fieldTypes.TEXT,
          hideInform: true,
        },
        {
          key: "nome_projeto",
          name: "Projeto",
          type: this.$fieldTypes.TEXT,
          hideInform: true,
        },
        {
          key: "nome_atividade",
          name: "Atividade",
          type: this.$fieldTypes.TEXT,
          hideInform: true,
        },
        {
          key: "consultores",
          name: this.isAdmin ? "Colaboradores" : "Colaborador",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: 'consultores', key: 'id', name: 'nome' },
          placeholder: 'Selecione um ou mais Colaboradores',
          rules: [{ rule: 'required' }],
          editable: this.isAdmin,
          hideInTable: true,
          colSize: 6,
        },
        {
          key: "empresaId",
          name: "Empresa",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'empresas', key: 'id', name: 'empresa' },
          placeholder: 'Selecione uma Empresa',
          rules: [{ rule: 'required' }],
          hideInTable: true,
          colSize: 6,
        },
        {
          key: "projetoAtividadeId",
          name: "Projeto",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'projetos', key: 'id', name: 'projeto' },
          placeholder: 'Selecione um projeto',
          rules: [{ rule: 'required' }],
          valueChanged: (projetoAtividadeId) => this.getAtividades(projetoAtividadeId),
          hideInTable: true,
          colSize: 6,
        },
        {
          key: "atividadeId",
          name: "Atividade",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'atividades', key: 'id', name: 'atividade' },
          placeholder: !this.selectedItem.projetoAtividadeId ? 'Selecione um projeto antes de uma atividade' : 'Selecione uma atividade',
          rules: [{ rule: 'required' }],
          editable: !!this.selectedItem.projetoAtividadeId,
          hideInTable: true,
          colSize: 6,
        },
      ];
      const finalCols = [{
        key: "total",
        name: "Total",
        type: this.$fieldTypes.TIME,
        hideInform: true,
      }];
      const weekdays = this.selectedWeek.map((day) => ({
        key: day,
        name: toWeekDay(day),
        type: this.$fieldTypes.TIME,
        editOnTable: true,
        editable: this.dateInsideProjectPeriod(day),
        hideInform: !this.dateInsideProjectPeriod(day),
        align: 0,
        width: 98,
        colSize: 3,
        commentForField: `comment${day}`,
      }));

      return [...initialCols, ...weekdays, ...finalCols];
    },
    customResource: function () {
      const resource = this.apiResource(`v1/timesheet/consultor/alocacao${this.funcionarioId ? `/${this.funcionarioId}` : ''}`);
      return {
        ...resource,
        get: (param) => {
          return resource
            .get(param)
            .then((result) => {
              if (result.error) {
                return result;
              }

              this.timeSheetsOfWeek = result.timesheets ? result.timesheets : result;
              let timesheets = this.timeSheetsOfWeek.reduce((acc = [], ts) => {
                const { atividadeId, comentario, consultorId, data, empresaId, fantasia, nome_atividade, nome_consultor, nome_projeto, projetoAtividadeId, tempo } = ts;
                const found = acc.find(({ atividadeId, consultorId, empresaId, projetoAtividadeId }) => atividadeId === ts.atividadeId && consultorId === ts.consultorId && empresaId === ts.empresaId && projetoAtividadeId === ts.projetoAtividadeId);
                const foundIndex = found ? acc.indexOf(found) : -1;
                let row = found || {
                  atividadeId,
                  consultorId,
                  empresaId,
                  fantasia,
                  id: [],
                  nome_atividade,
                  nome_consultor,
                  nome_projeto,
                  projetoAtividadeId,
                  total: 0,
                };
                row.id.push(ts);
                row[data] =
                  (row[data] ? Number(row[data]) : 0) + Number(tempo);
                row[`comment${data}`] = comentario;
                row.total += Number(tempo);

                if (foundIndex >= 0) {
                  acc.splice(foundIndex, 1, found);
                  return acc;
                }

                return [...acc, row];
              }, []);

              this.parsedTimeSheetsOfWeek = timesheets;
              return timesheets;
            });
        },
        save: async (obj, id) => {
          let operations = { save: [], delete: [] };

          if (!Array.isArray(obj.consultores)) {
            obj.consultores = obj.consultorId ? [obj.consultorId] : [];
          }

          forEach(obj, (value, key) => {
            if (moment(key, "YYYY-MM-DD", true).isValid()) {
              obj.consultores.forEach((consultorId) => {
                const exist = (id || this.timeSheetsOfWeek).find((o) =>
                  o.data == key &&
                  o.atividadeId == obj.atividadeId &&
                  o.consultorId == consultorId &&
                  o.empresaId == obj.empresaId &&
                  o.projetoAtividadeId == obj.projetoAtividadeId
                );
                const newValue = parseFloat(`${value}`).toFixed(4) * 1;
                const oldValue = parseFloat(`${exist?.tempo || 0}`).toFixed(4) * 1;
                const oldComment = exist ? (exist[`comment${key}`] || exist.comentario) : null;
                const newComment = obj[`comment${key}`];
                const hasChanges = !compareFloatNum(newValue, oldValue) || newComment != oldComment;

                if (hasChanges && newValue > 0) {
                  operations.save.push({
                    atividadeId: obj.atividadeId,
                    consultorId: consultorId,
                    comentario: newComment,
                    data: key,
                    empresaId: obj.empresaId,
                    id: exist ? exist.id : undefined,
                    projetoAtividadeId: obj.projetoAtividadeId,
                    tempo: newValue,
                  });
                } else if (hasChanges && exist) {
                  operations.delete.push(exist.id);
                }
              });
            }
          });

          const actions = [];
          operations.save.forEach((objToSave) => {
            actions.push(resource.save(objToSave, objToSave.id));
          });
          operations.delete.forEach((idToDelete) => {
            actions.push(resource.delete(idToDelete));
          });

          const response = await Promise.all(actions);
          response.forEach(res => {
            if ('deletado' in res) {
              remove(this.timeSheetsOfWeek, ({ id }) => res.deletado == id);
              remove(id, ({ id }) => res.deletado == id);
            } else {
              const index = this.timeSheetsOfWeek.findIndex(({ id }) => res.id == id);

              if (index === -1) {
                this.timeSheetsOfWeek.push(res);

                if (id) {
                  id.push(res);
                }
              } else {
                this.timeSheetsOfWeek.splice(index, 1, res);
                id.splice(index, 1, res);
              }
            }
          })

          this.timeSheetsOfWeek = cloneDeep(this.timeSheetsOfWeek);
          obj.total = 0;
          this.selectedWeek.forEach((key) => {
            obj.total += obj[key] || 0;
          });

          return response;
        },
        delete: async (ids) => {
          const timesheets = Array.isArray(ids) ? ids : [];
          const requests = timesheets.reduce((acc, { id }) => {
            if (id) {
              const req = resource.delete(id);
              return [...acc, req];
            }

            return acc;
          }, []);
          const response = await Promise.all(requests);
          const idsDeletados = response.map(({ deletado }) => typeof deletado === 'string' ? parseInt(deletado, 10) : deletado).sort();
          const row = this.parsedTimeSheetsOfWeek.find(({ id: originalTimesheets }) => (originalTimesheets || []).some((t) => idsDeletados.includes(t.id)));

          if (row) {
            this.selectedWeek.forEach((key) => {
              row[key] = null;
              row[`comment${key}`] = null;
            });
            row.id = [];
            row.total=0;
          }

          remove(this.timeSheetsOfWeek, (t) => idsDeletados.includes(t.id));
          return response;
        }
      };
    },
    empresaFields: function () {
      return [
        {
          key: "empresa",
          name: "Empresa",
          type: this.$fieldTypes.TEXT,
          placeholder: 'Escreva o nome da empresa',
          rules: [{ rule: 'required' }],
        },
      ];
    },
    filterQuery: function () {
      const [dataIni, dataFim] = this.period;
      return `dataIni=${dataIni}&dataFim=${dataFim}${this.consultorId ? `&consultorId=${this.consultorId}` : ''}`;
    },
    funcionarioId: function () {
      return this.$store.getters.jwtData.data.link.funcionarioId;
    },
    horasTotaisPorDia: function () {
      const colaboradoresNaTabela = this.timeSheetsOfWeek.map(({ funcionarioId }) => funcionarioId);
      const uniqColaboradores = _.uniq(colaboradoresNaTabela);
      
      if (uniqColaboradores.length === 1) {
        return this.timeSheetsOfWeek.reduce((acc, { data, tempo }) => {
          const temp = { ...acc };
          const parsedTempo = tempo * 1;
          if (data in temp) {
            temp[data] += parsedTempo;
          } else {
            temp[data] = parsedTempo;
          }
          return temp;
        }, {});
      }

      return null;
    },
    isAdmin: function () {
      return this.user.tipo_usuario === UserTypeEnum.ADMINISTRADOR;
    },
    opts: function () {
      return {
        atividades: this.atividades,
        empresas: this.empresas,
        consultores: this.consultores,
        projetos: this.projetos,
        // TODO temporário, até back ter um endpoint para retornar os tipos de projetos
        tiposProjetos: [
          {
            name: "Ativação",
          },
          {
            name: "Captação",
          },
          {
            name: "Contestação",
          },
          {
            name: "Crédito Presumido",
          },
          {
            name: "DIG",
          },
          {
            name: "Lei de Informática",
          },
          {
            name: "Lei do Bem",
          },
          {
            name: "Rota 2030",
          },
        ]
      }
    },
    projetoFields: function () {
      return [
        {
          key: "tipo",
          name: "Tipo",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'tiposProjetos', id: 'name', name: 'name' },
          placeholder: 'Selecione o tipo do projeto',
          rules: [{ rule: 'required' }],
        },
        {
          key: "projeto",
          name: "Projeto",
          type: this.$fieldTypes.TEXT,
          placeholder: 'Escreva o nome de acordo com o tipo',
          rules: [{ rule: 'required' }],
        },
      ];
    },
    resourceAtividadesGalapos: function () {
      return this.apiResource(`v1/timesheet/consultor/atividades`);
    },
    resourceAsyncExport: function () {
      return this.apiResource(`v1/clientes/${this.clientId}/exportacao`);
    },
    resourceEmpresasGalapos: function () {
      return this.apiResource(`v1/timesheet/consultor/clientes`);
    },
    resourceConsultores: function () {
      return this.apiResource(`/v1/consultores`);
    },
    resourceProjetosGalapos: function () {
      return this.apiResource(`/v1/timesheet/consultor/projetos`);
    },
    selectedWeek: function () {
      return this.weekFromDate(this.period[0]);
    },
  },
  data: function () {
    return {
      adicionarModal: false,
      adicionarEmpresaModal: false,
      adicionarProjetoModal: false,
      adicionarAtividadeModal: false,
      atividades: [],
      competenciaExportacao: this.$store.getters.intervaloCompetencia,
      consultores: [],
      consultorId: null,
      dateMenu: false,
      doLoad: () => {},
      errorMessage: false,
      empresas: [],
      exportacaoAsync: false,
      highlightWeekOver: [],
      parsedTimeSheetsOfWeek: {},
      period: [],
      periodMenu: false,
      projetos: [],
      selectedItem: {},
      showSucessExport: false,
      timeSheetsOfWeek: [],
    };
  },
  methods: {
    confirmDeleteMessage: function ({ fantasia, nome_atividade, nome_consultor, nome_projeto }) {
      return `Deseja excluir todos os registros da semana para a empresa ${fantasia || '[não encontrao]'}, projeto ${nome_projeto || '[não encontrao]'}, atividade ${nome_atividade || '[não encontrao]'} do colaborador ${nome_consultor || '[não encontrao]'}?`;
    },
    dateClick: function () {
      this.period = this.getWeekPeriod(moment(this.period[0]));
      this.periodMenu = this.period.length != 2;
    },
    dateInsideProjectPeriod: function (date) {
      return (row) => {
        if (!row || !Array.isArray(this.projetos)) {
          return true;
        }

        const { projetoAtividadeId } = row;
        const projeto = this.projetos.find(({ id }) => id === projetoAtividadeId);

        if (!projeto) {
          return true;
        }

        const { data_inicio, data_fim } = projeto;
        const res = !data_inicio || !data_fim || data_inicio <= date && date <= data_fim;

        return res;
      };
    },
    disableTableSaveButton: function (row) {
      const exist = _.filter(this.timeSheetsOfWeek, (ts) =>
        ts.data in row &&
        ts.empresaId == row.empresaId &&
        ts.projetoAtividadeId == row.projetoAtividadeId &&
        ts.atividadeId == row.atividadeId &&
        ts.consultorId == row.consultorId
      );
      return !this.selectedWeek.some((day) => {
        const timesheet = exist.find(({ data }) => data === day);
        const newTempo = parseFloat(`${row[day]}`).toFixed(4) * 1;
        const oldTempo = parseFloat(`${timesheet?.tempo || 0}`).toFixed(4) * 1;
        const oldComment = timesheet ? (timesheet[`comment${day}`] || timesheet.comentario) : null;
        const newComment = row[`comment${day}`];

        return timesheet ? (!compareFloatNum(newTempo, oldTempo) || newComment != oldComment) : newTempo > 0;
      });
    },
    doAsyncExport: function () {
      const body = {
        tipoRelatorio: 'timesheet-consultores',
        competenciaIni: this.competenciaExportacao[0],
        competenciaFim: this.competenciaExportacao[1],
      };
      this.resourceAsyncExport.save(body).then((response) => {
        if (response.error) {
          this.errorMessage = response.error;
          return;
        }

        this.errorMessage = false;
        this.exportacaoAsync = false;
        this.showSucessExport = true;
      });
    },
    doSave: function (body, closeCb) {
      this.customResource.save(body).then((response) => {
        if (response.error) {
          this.errorMessage = response.error;
          return;
        }

        this.errorMessage = false;
        this.doLoad();
        closeCb();
      });
    },
    doSaveAtividade: function (body, closeCb) {
      this.resourceAtividadesGalapos.save(body).then((response) => {
        if (response.error) {
          this.errorMessage = response.error;
          return;
        }

        this.errorMessage = false;
        this.atividades = [];
        closeCb();
      });
    },
    doSaveEmpresa: function (body, closeCb) {
      this.resourceEmpresasGalapos.save(body).then((response) => {
        if (response.error) {
          this.errorMessage = response.error;
          return;
        }

        this.errorMessage = false;
        this.doLoadEmpresas();
        closeCb();
      });
    },
    doSaveProjeto: function (body, closeCb) {
      this.resourceProjetosGalapos.save(body).then((response) => {
        if (response.error) {
          this.errorMessage = response.error;
          return;
        }

        this.errorMessage = false;
        this.doLoadProjetos();
        closeCb();
      });
    },
    doLoadEmpresas: function () {
      return this.resourceEmpresasGalapos.get().then((response) => {
        this.empresas = response.sort((a, b) => a.empresa.localeCompare(b.empresa));
      });
    },
    doLoadProjetos: function () {
      return this.resourceProjetosGalapos.get().then((response) => {
        this.projetos = response.sort((a, b) => a.projeto.localeCompare(b.projeto));
      });
    },
    getAtividades: function (projetoAtividadeId) {
      if (!projetoAtividadeId) {
        return;
      }

      this.resourceAtividadesGalapos.get(projetoAtividadeId).then((response) => {
        if (response.error) {
          this.atividades = [];
        } else {
          this.atividades = response;
        }
      });
    },
    getWeekPeriod: function (date) {
      let sunday = moment(date).day(0);
      let saturday = moment(sunday).day(6);
      return [sunday.format("YYYY-MM-DD"), saturday.format("YYYY-MM-DD")];
    },
    highlightWeek: function (date) {
      this.highlightWeekOver = this.weekFromDate(date);
    },
    verFila: function () {
      this.$router.push({ name: "listagem-relatorios-assincronos" });
    },
    weekFromDate: function (date) {
      let format = "YYYY-MM-DD";
      return new Array(7).fill(date).map((_, index) => moment(date).day(index).format(format));
    },
  },
};
</script>
